.product-card{
    display: flex;
    align-items: center;
    justify-content: center;
}

.product__item{
    overflow: hidden;
    border-radius: 10px;
    width: 200px;
    height: 300px;
    cursor: pointer;
    margin-top: 30px;
    position: relative;
    /* background: var(--secondary-color); */
    
}   
.info{
    padding: 6px;
    background: var(--primary-color);
    color: var(--text-color);
    width: 100%;
    height: 100%;
    /* transition:   .3s ease-in-out; */
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
}

.product__item:hover .info{
    position: absolute;
    transform: translateY(-67%);
    overflow-y: scroll;
}

.product__img img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 245px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.product__name:hover a{
    color: var(--text-color);
}

.product__name a {
    color: var(--text-light);
    font-size: var(--font-size);
    transition: .2s ease-in-out;
}