.sidebar-btn {
  display: none;
}


@media only screen and (max-width: 768px) {
  .sidebar-btn {
    display: block;
  }
  .black-everything {
    position: absolute;
    background: #0000008b;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: none;
  }

  .active__black {
    display: flex;
  }

  .navbar .nav-links {
    display: flex;
    flex-direction: column;
    background: var(--primary-color) !important;
    
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 70vw;
    
    align-items: center;
    justify-content: center;
    z-index: 9;
    display: none;
    /* animation: transition 0.3s ease-in-out; */
  }
  /* @keyframes transition {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  } */
  .navbar .active__menu {
    display: flex;
  }
  .nav-links a {
    margin: 30px 0px;
    color: var(--text-color) !important;
  }
}
