.form-section form {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 2px 0px;
  height: 40px;
}
.search-recipe {
  width: 85%;
  padding: 4px 10px;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--secondary-color);
  outline: none;
  transition: 0.2s ease-in-out;
}

.form-section form input{
  border-top-right-radius: 5px; 
  border-top-left-radius: 5px; 
  background-color: var(--background-color);
  color: var(--text-color) !important;
}

.form-section form input::placeholder{
  color: var(--text-color) !important;
  opacity: .5;
}

.form-section form .search-btn{
    background: transparent;
    padding: 5px 20px;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--secondary-color);
    transition: 0.2s ease-in-out;
    border-radius: 10px;
    box-shadow: 0 0px 7px var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-section form .search-btn:hover{
    color: #fff;
    border-bottom: 1px solid var(--primary-color);
    background: var(--primary-color);
}

.search-recipe:focus {
  border-bottom: 1px solid var(--primary-color);
}

