@import "partials/recipes-section";

:root {
  --background-color: #fff;
  --background-light: #fff;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --primary-color: rgba(255, 0, 86);
  --secondary-color: rgba(65, 76, 80, 0.551);
  --text-color: #0a0a0a;
  --text-light: #575757;
  --font-size: 16px;
  --animation-speed: 1;
}

@function getAnimationDuration($duration: number) {
  @return calc($duration * var(--animation-speed));
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: var(--font-size) !important;
  font-family: "Roboto", sans-serif;
  background-color: var(--background-light) !important;
}

a {
  text-decoration: none !important;
  color: var(--text-light);
}

.btn {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}

.navbar .nav-links{
  animation: transition getAnimationDuration(0.2s) ease-in-out; 
}

.info{
  transition: getAnimationDuration(0.2s) ease-in-out;
}
@keyframes transition {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.navbar {
  height: 55px;
  max-width: 100vw !important;
  box-shadow: 0 3px 6px var(--shadow-color);
  background-color: var(--background-color);
  color: var(--text-light);
  display: flex;
  justify-content: space-around !important;
  align-items: center;

  .logo {
    font-size: 1.5em;
    font-weight: bolder;
    color: var(--primary-color);
    text-shadow: 2px 2px 13px var(--shadow-color);
    span {
      color: var(--text-color);
    }
  }

  .nav-links {
    a {
      margin-left: 1em;
      color: var(--text-light);
      letter-spacing: 2px;
      text-transform: uppercase;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        height: 1.5px;
        width: 100%;
        background: var(--primary-color);
        position: absolute;
        bottom: -3px;
        left: 0;
        transform: scaleX(0);
        transition: getAnimationDuration(0.2s) ease-in-out;
      }
      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
    }
    & .active {
      color: var(--primary-color);
      font-weight: bolder;
    }
  }

  .sidebar-btn {
    height: 28px;
    width: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin-left: 100px;
    .bar {
      border-radius: 5px;
      height: 6px;
      width: 100%;
      background-color: var(--text-color);
      position: absolute;
      transition: getAnimationDuration(0.2s) ease-in-out;
      &:nth-child(1) {
        top: 0%;
        transform: scaleY(0.9);
        // width: 90%;
        // right: 0;
      }
      &:nth-child(2) {
        top: 50%;
        transform: scaleX(0.8) scaleY(0.9) translateY(-50%);
        // width: 60%;
        // right: 0;
        transform-origin: right;
      }
      &:nth-child(3) {
        top: 100%;
        transform: scaleX(1) scaleY(0.9) translateY(-100%);
        transform-origin: right;
        // width: 100%;
        // right: 0;
      }
    }
  }
  & .active {
    .bar {
      transform-origin: center;
      background-color: var(--primary-color) !important;
      top: 50%;
      &:nth-child(1) {
        transform: scale(0.6) rotate(45deg) translateY(100%) translateX(25%);
      }
      &:nth-child(2) {
        transform: scale(0);
      }
      &:nth-child(3) {
        transform: scale(0.6) rotate(-45deg) translateY(-710%) translateX(70%);
      }
    }
  }
}

.container {
  padding-inline: 15vw;
}
// settings

.btn{
  font-size: var(--font-size) !important;
}

.options-container {
  box-shadow: 0 5px 10px var(--shadow-color);
  padding: 1.5em;
  border-radius: 5px;
  border-left: 0.5em solid var(--primary-color);
  background-color: var(--background-light);
  margin-top: 1em;
  display: flex;
  gap: 1em;

  .option {
    cursor: pointer;
    height: 3.4em;
    width: 3.4em;
    box-shadow: 0 3px 6px var(--shadow-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &.light {
      background-color: #fff !important;
    }

    &.dark {
      background-color: rgb(29, 29, 29);
    }

    .check {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 2em;
      width: 2em;
      background-color: var(--primary-color);
      box-shadow: 0px 1px 12px 3px rgba(0, 0, 0, 0.561);
      svg {
        height: 1.5em;
        width: 1.5em;
      }
    }
  }

  .btn span {
    display: inline-block;
    margin-left: 0.5em;
    color: var(--text-color);
  }
}

.main-title {
  font-size: 1.75em;
  color: var(--text-color);
}
